import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'gatsby-link';
import BlogPostCard from '../BlogPostCard/BlogPostCard';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '200px',
      marginRight: '200px',
    },
  },
}));

const LatestBlogPosts = () => {
  const classes = useStyles();
  const latestArticleData = useStaticQuery(
    graphql`
      query latestArticleQuery {
        allWordpressWpBlogs(limit: 2, sort: { fields: date, order: DESC }) {
          edges {
            node {
              title
              slug
              date
              acf {
                author
                excerpt
                hero_image {
                  source_url
                }
              }
            }
          }
        }
      }
    `,
  );

  return (
    <div className={classes.container}>
      <Typography variant="h2" gutterBottom>
        Latest Articles
      </Typography>
      <Link to="/articles">See all Articles</Link>
      <div>
        {latestArticleData.allWordpressWpBlogs.edges.map(({ node }) => (
          <BlogPostCard
            key={node.slug}
            title={node.title}
            slug={node.slug}
            id={node.slug}
            date={node.date}
            author={node.acf.author}
            excerpt={node.acf.excerpt}
            sourceUrl={node.acf.hero_image.source_url}
          />
        ))}
      </div>
    </div>
  );
};

export default LatestBlogPosts;
